import React from 'react'
import ConfirmLayout from 'src/templates/confirm'
import PageHead from 'src/components/head/PageHead'

// Styles & Images
import RampIcon from 'static/confirm/images/ramp--icon.svg'

// Main component:
const XopayRedirectPage = ({ location }) => (
  <ConfirmLayout
    backgroundGradient
    deepLinkPath="xo-pay"
    fullLogo
    icon={RampIcon}
    iconSize="largest"
    location={location}
    page="xo-pay"
  />
)

export default XopayRedirectPage

// <head> component:
export function Head() {
  return <PageHead page="xo-pay" noindex />
}
